import { billingDaysChoices, getInvalidBillingDays } from "@smartrr/shared/utils/dateUtils";
import { useEffect, useMemo, useState } from "react";
import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";

export const useDisabledDatesByBillingDays = (day: Date, omitDates?: Date[] | undefined) => {
  const isLoading = useSmartrrVendorSelector(state => state.vendorOrganizations.isLoadingSetup);
  const dayVals = useMemo(() => billingDaysChoices.map(billingDay => billingDay.value), []);
  const setup = useSmartrrVendorSelector(state => state.vendorOrganizations.setup);
  const [selectedBillingDays, setSelectedBillingDays] = useState<string[]>(setup.billingDays ?? dayVals);

  useEffect(() => {
    if (!isLoading && setup.billingDays) {
      setSelectedBillingDays(setup.billingDays);
    }
  }, [isLoading, setup.billingDays]);

  return useMemo(() => getInvalidBillingDays(selectedBillingDays, day, omitDates), [selectedBillingDays, day]);
};
