import {
  ISmartrrAddOnsConfig,
  ISmartrrSellingPlanGroup,
} from "@smartrr/shared/entities/SellingPlanGroup";
import { captureException } from "@smartrr/shared/utils/captureException";
import { getSellingPlanToProductAndVariantMapsFromSellingPlanGroups } from "@smartrr/shared/utils/sellingPlans";
import React, { useCallback, useContext, useEffect, useState } from "react";

import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";

import { SellingPlanGroup } from "../../types";

export interface SellingPlansContextType {
  sellingPlanGroups: ISmartrrSellingPlanGroup[];
  addOnsConfig: ISmartrrAddOnsConfig | null;
  sellingPlanGroupsByProductId: SellingPlanGroup;
  sellingPlanGroupsByVariantId: SellingPlanGroup;
  sellingPlanGroupsLoading: boolean;
  getSellingPlanGroup: (sellingPlanId: any) => ISmartrrSellingPlanGroup | undefined;
}

interface SellingPlansProviderProps {
  children: React.ReactNode;
}

const SellingPlansContext = React.createContext<SellingPlansContextType>({
  addOnsConfig: null,
  sellingPlanGroupsByProductId: {},
  sellingPlanGroupsByVariantId: {},
  sellingPlanGroups: [],
  sellingPlanGroupsLoading: false,
  getSellingPlanGroup: () => undefined,
});

export const useSellingPlans = () => useContext(SellingPlansContext);

export const fetchSellingPlanGroups = () => {
  return typedFrontendVendorApi.getReq("/selling-plan-group");
};

export const fetchAddonsConfig = () => {
  return typedFrontendVendorApi.getReq("/add-ons-config");
};

export function SellingPlansProvider({ children }: SellingPlansProviderProps): JSX.Element {
  const { addToast } = useToast();
  const [sellingPlanGroupsLoading, setSellingPlanGroupsLoading] = useState(true);

  const [sellingPlanGroupsByProductId, setSellingPlanGroupsByProductId] = useState<SellingPlanGroup>({});
  const [sellingPlanGroupsByVariantId, setSellingPlanGroupsByVariantId] = useState<SellingPlanGroup>({});
  const [sellingPlanGroups, setSellingPlanGroups] = useState<ISmartrrSellingPlanGroup[]>([]);
  const [addOnsConfig, setAddOnsConfig] = useState<ISmartrrAddOnsConfig | null>(null);

  const getSellingPlanGroup = useCallback(
    sellingPlanId => {
      return sellingPlanGroups.find(spg => spg.sellingPlans.find(sp => sp.shopifyId === sellingPlanId));
    },
    [sellingPlanGroups]
  );

  const getSellingPlanGroups = useCallback(async () => {
    setSellingPlanGroupsLoading(true);

    try {
      const configList = await typedFrontendVendorApi.getReq("/selling-plan-configs");

      if (configList.type === "success") {
        const { sellingPlanGroupsByProductId, sellingPlanGroupsByVariantId } =
          getSellingPlanToProductAndVariantMapsFromSellingPlanGroups(configList.body.sellingPlanGroups);

        setSellingPlanGroups(configList.body.sellingPlanGroups);
        setSellingPlanGroupsByProductId(sellingPlanGroupsByProductId);
        setSellingPlanGroupsByVariantId(sellingPlanGroupsByVariantId);
        setAddOnsConfig(configList.body.addOnConfigs[0] ?? null);
      } else {
        addToast("Error loading subscription programs");
      }
    } catch (error) {
      captureException("Error loading subscription programs", error);
      addToast("Error loading subscription programs");
    }

    setSellingPlanGroupsLoading(false);
  }, []);

  useEffect(() => {
    getSellingPlanGroups();
  }, []);

  return (
    <SellingPlansContext.Provider
      value={{
        addOnsConfig,
        sellingPlanGroupsByProductId,
        sellingPlanGroupsByVariantId,
        sellingPlanGroupsLoading,
        sellingPlanGroups,
        getSellingPlanGroup,
      }}
    >
      {children}
    </SellingPlansContext.Provider>
  );
}
